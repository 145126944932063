function CalculatorTruck() {
  const features = [
    {
      title: "US Toll Calculator",
      link: "https://tollguru.com/toll-calculator",
    },
    {
      title: "Europe Toll Calculator",
      link: "https://tollguru.com/toll-calculator-europe",
    },
    {
      title: "India Toll Calculator",
      link: "https://tollguru.com/toll-calculator-india",
    },
    {
      title: "Asia Toll Calculator",
      link: "https://tollguru.com/toll-calculator-asia",
    },
    {
      title: "UK Toll Calculator",
      link: "https://tollguru.com/toll-calculator-united-kingdom",
    },
    {
      title: "France Toll Calculator",
      link: "https://tollguru.com/toll-calculator-france",
    },
    {
      title: "Canada Toll Calculator",
      link: "https://tollguru.com/toll-calculator-canada",
    },
    {
      title: "Italy Toll Calculator",
      link: "https://tollguru.com/italy-toll-calculator",
    },
    {
      title: "Brazil Toll Calculator",
      link: "https://tollguru.com/toll-calculator-brazil",
    },
    {
      title: "Australia Toll Calculator",
      link: "https://tollguru.com/toll-calculator-australia",
    },
    {
      title: "Mexico Toll Calculator",
      link: "https://tollguru.com/toll-calculator-mexico",
    },
    {
      title: "Spain Toll Calculator",
      link: "https://tollguru.com/toll-calculator-spain",
    },
    {
      title: "Indonesia Toll Calculator",
      link: "https://tollguru.com/toll-calculator-indonesia",
    },
    {
      title: "Turkey Toll Calculator",
      link: "https://tollguru.com/toll-calculator-turkey",
    },
    {
      title: "Poland Toll Calculator",
      link: "https://tollguru.com/toll-calculator-poland",
    },
    {
      title: "Argentina Toll Calculator",
      link: "https://tollguru.com/toll-calculator-argentina",
    },
    {
      title: "Belgium Toll Calculator",
      link: "https://tollguru.com/toll-calculator-belgium",
    },
    {
      title: "Ireland Toll Calculator",
      link: "https://tollguru.com/toll-calculator-ireland",
    },
  ];

  return (
    <div className="relative mt-20 md:mt-52 lg:mt-44 xl:mt-0 lg:mx-24 xl:my-0">
      <h3 className="md:hidden mt-0 text-2xl lg:text-3xl  text-tollguru-heading font-bold mb-8">
        Save upto 14% with Toll Calculator in Action
      </h3>
      <div className="relative">
        <div className="absolute -top-[5vh] md:-top-24 lg:-top-20 xl:top-16 2xl:top-40 left-2 md:left-4 w-[67%] md:w-[67%] lg:w-[69%] xl:w-[69%] min-h-72 flex">
          <div className="absolute z-10 top-10 lg:top-20 w-full max-h-[20vh] md:max-h-[35vh] lg:max-h-[28vh] xl:max-h-[25vh] 2xl:!max-h-[15vh] mx-auto overflow-scroll hide-scrollbar md:block">
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-3 md:gap-4 w-full">
              {features.map((item) => (
                <a href={item.link}>
                  <div className="h-full flex items-center rounded-md justify-center relative px-5 py-2 overflow-hidden border shadow-md place-items-center bg-white hover:bg-text-gradient shadow-gray-900/20 hover:text-white hover:border-transparent group">
                    <p className="block font-sans antialiased leading-snug tracking-normal text-md bg-text-gradient text-transparent bg-clip-text group-hover:text-white !text-center">
                      {item.title}
                    </p>
                  </div>
                </a>
              ))}
            </div>
          </div>
          <h3 className="hidden md:block mt-0 text-2xl lg:text-3xl text-tollguru-heading font-bold absolute top-[280px] text-center left-0 z-50 border shadow-md place-items-center bg-white px-5 py-2 w-full shadow-gray-900/20">
            Save upto 14% with Toll Calculator in Action
          </h3>
        </div>
      </div>
      <img
        src="https://cdn.tollguru.com/tollguru_v2/images/assets/landing-page-images/truck_gradient.webp"
        alt=""
      />
    </div>
  );
}

export default CalculatorTruck;
